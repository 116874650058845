import React from "react";
import NewsArticleLayout from "../../../components/updated-article/NewsArticleLayout.component";

const isNew = true;
const href = "/ratesheet/20230130-Rate-Sheet.pdf";
const date = "30.01.2023";

const Article = () => (
  <NewsArticleLayout href={href} date={date} isNew={isNew}>
    <h1 className="page-title">Changes to our mortgage products</h1>

    <br />
    <p>With effect from Monday 30 January 2023 we will be making the following changes to our range:</p>
    <p>
      <strong>Summary of changes</strong>
    </p>
    <p>
      We are changing our Residential Standard Variable Rate from 6.29% to 6.79% and our Buy to Let Standard Variable
      Rate from 5.85% to 6.35%.
    </p>
    <p>
      Any customers who are currently on our Standard Variable Rates will be notified of changes to their payments in
      writing.
    </p>
    <p>We will also be making the following changes to the rest of our range:</p>
    <p>
      <strong>UK Residential Existing Customer Switching Range</strong>
    </p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80% and 85% LTV have reduced</li>
      <li>2 Year Fixed Standard at 60%, 70%, 75%, 80% and 85% LTV have reduced</li>
      <li>3 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>3 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Fee Saver at 60%, 70% and 75% LTV have reduced</li>
      <li>5 Year Fixed Standard at 60%, 70%, 75%, 80% and 85% LTV have reduced</li>
      <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75%, 80% and 85% LTV have reduced</li>
      <li>2 Year Tracker Standard at 60%, 70%, 75%, 80% and 85% LTV have reduced</li>
    </ul>
    <p>
      <strong>UK Residential Existing Customer Borrowing More Range</strong>
    </p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80% and 85% LTV have reduced</li>
      <li>2 Year Fixed Standard at 60%, 70%, 75%, 80% and 85% LTV have reduced</li>
      <li>3 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>3 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Fee Saver at 60%, 70% and 75% LTV have reduced</li>
      <li>5 Year Fixed Standard at 60%, 70%, 75%, 80% and 85% LTV have reduced</li>
      <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75%, 80% and 85% LTV have reduced</li>
      <li>2 Year Tracker Standard at 60%, 70%, 75%, 80% and 85% LTV have reduced</li>
    </ul>
    <p>
      <strong>UK Residential First Time Buyer Range</strong>
    </p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 70% and 75% LTV have reduced</li>
      <li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 90% and 95% LTV have reduced</li>
      <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>2 Year Tracker Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
    </ul>
    <p>
      <strong>UK Residential Home Mover Range</strong>
    </p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 70% and 75% LTV have reduced</li>
      <li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 90% and 95% LTV have reduced</li>
      <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>2 Year Tracker Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced </li>
    </ul>
    <p>
      <strong>UK Residential Remortgage Range</strong>
    </p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 70% and 75% LTV have reduced</li>
      <li>2 Year Fixed Standard at 60%, 70%, 75%, 80% and 85% LTV have reduced</li>
      <li>5 Year Fixed Fee Saver at 60%, 70%, 75% and 90% have reduced</li>
      <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>2 Year Tracker Standard at 60%, 70%, 75%, 80% and 85% LTV have reduced </li>
    </ul>
    <p>
      <strong>UK Buy to Let Existing Customer Switching Range</strong>
    </p>
    <ul>
      <li>2 Year Fixed Standard at 60% and 65% LTV have reduced</li>
      <li>5 Year Fixed Standard at 60% LTV has reduced</li>
    </ul>
    <p>
      <strong>UK Buy to Let Existing Customer Borrowing More Range</strong>
    </p>
    <ul>
      <li>2 Year Fixed Standard at 60% and 65% LTV have reduced</li>
      <li>5 Year Fixed Standard at 60% LTV has reduced</li>
    </ul>
    <p>
      <strong>UK Buy to Let Purchase Range</strong>
    </p>
    <ul>
      <li>5 Year Fixed Standard at 60% LTV has reduced</li>
      <li>2 Year Tracker Fee Saver at 60%, 65% and 75% LTV have reduced</li>
      <li>2 Year Tracker Standard at 60%, 65% and 75% LTV have reduced</li>
    </ul>
    <p>
      <strong>UK Buy to Let Remortgage Range</strong>
    </p>
    <ul>
      <li>2 Year Fixed Standard at 60% and 75% LTV have reduced</li>
      <li>5 Year Fixed Standard at 60% LTV has reduced</li>
      <li>2 Year Tracker Fee Saver at 60%, 65% and 75% LTV have reduced</li>
      <li>2 Year Tracker Standard at 60%, 65% and 75% LTV have reduced</li>
    </ul>
    <p>
      <strong>International Residential Range</strong>
    </p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 70% and 75% LTV have reduced</li>
      <li>2 Year Fixed Standard at 60%, 70% and 75% LTV have reduced</li>
      <li>5 Year Fixed Fee Saver at 60%, 70% and 75% LTV have reduced</li>
      <li>5 Year Fixed Standard at 60%, 70% and 75% LTV have reduced</li>
      <li>5 Year Fixed Premier Exclusive at 60%, 70% and 75% LTV have reduced</li>
      <li>2 Year Tracker Standard at 60%, 70% and 75% LTV have reduced</li>
    </ul>
    <p>All of our fixed rate end dates will be extended as below:</p>
    <ul>
      <li>2 year fixed rates – 30 June 2025</li>
      <li>3 year fixed rates – 30 June 2026</li>
      <li>5 year fixed rates – 30 June 2028</li>
    </ul>
    <p>There are no changes to any other interest rates at this time.</p>
    <p>An updated mortgage rate sheet will be published on Monday 30 January to reflect these changes.</p>
  </NewsArticleLayout>
);

export default Article;
